import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';
import ConsumerLayout from 'components/ConsumerLayout';
import { useVendorsContext } from 'components/VendorsContext';

const Index = () => {
  const router = useRouter();
  const { hasError, isLoading } = useVendorsContext();
  if (!isLoading) {
    if (hasError) {
      router.push('/member/profile');
    } else {
      router.push('/landing');
    }
  }
  return (
    <ConsumerLayout pageTitle="Home">
      <Box as="h1" textStyle="h1"></Box>
    </ConsumerLayout>
  );
};

export default Index;
