import { Icon } from '@chakra-ui/react';

export const PaymentIcon = (props) => (
  <Icon viewBox="0 0 28 28" {...props}>
  <g clipPath="url(#clip0_6168_19195)">
    <path d="M14 27.9864C21.6589 27.9864 28 21.6345 28 13.9932C28 6.33808 21.6451 0 13.9863 0C6.34117 0 0 6.33808 0 13.9932C0 21.6345 6.35489 27.9864 14 27.9864ZM8.0843 19.8512C6.67057 19.8512 5.94314 19.1514 5.94314 17.7247V12.4704H22.0569V17.7247C22.0569 19.1514 21.3294 19.8512 19.9156 19.8512H8.0843ZM8.74312 17.7247H10.4314C10.8294 17.7247 11.1039 17.464 11.1039 17.0799V15.804C11.1039 15.42 10.8294 15.1592 10.4314 15.1592H8.74312C8.35882 15.1592 8.0843 15.42 8.0843 15.804V17.0799C8.0843 17.464 8.35882 17.7247 8.74312 17.7247ZM5.94314 10.9064V10.2754C5.94314 8.84862 6.67057 8.16269 8.0843 8.16269H19.9156C21.3294 8.16269 22.0569 8.86233 22.0569 10.2754V10.9064H5.94314Z" fill="#0072CE"/>
  </g>
  <defs>
    <clipPath id="clip0_6168_19195">
      <rect width="28" height="28" fill="white"/>
    </clipPath>
  </defs>
  </Icon>
);
