import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, createIcon, Flex, List, ListItem, useMediaQuery } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { CardIcon } from './Icons/CardIcon';
import { PaymentIcon } from './Icons/PaymentIcon';
import { SignOutIcon } from './Icons/SignOutIcon';
import { InventoryIcon } from './Icons/InventoryIcon';
import { QuestionMarkIcon } from './Icons/QuestionMarkIcon';
import SideBarNavLink from './SideBarNavLink';

type Props = {
  hasMenu: boolean;
  isOpen: boolean;
  isVendor: boolean;
};

const StationIcon = createIcon({
  displayName: 'StationIcon',
  viewBox: '0 0 200 200',
  path: (
    <path
      fill="currentColor"
      d="M100 0C44.8 0 0 44.8 0 100s44.8 100 100 100 100-44.8 100-100S155.2 0 100 0zm0 180c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80zM51 82.002c2.634 5.708 3.998 11.915 3.998 18.197A43.43 43.43 0 0 1 51 118.396l13.685 6.165c3.696-8.054 5.494-16.407 5.394-24.859-.1-8.353-1.898-16.407-5.394-23.865L51 82.002h0zm62.23-28.34c7.791 15.612 11.787 31.223 11.787 46.139 0 15.015-3.996 30.726-11.787 46.636L126.715 153C135.505 135.002 140 117.103 140 99.801S135.505 64.7 126.715 47l-13.485 6.662h0zM81.965 67.484c5.294 10.64 7.991 21.976 7.991 33.809 0 11.634-2.597 22.174-7.791 31.322l12.985 7.359c6.493-11.436 9.789-24.362 9.789-38.681a89.37 89.37 0 0 0-9.589-40.471l-13.385 6.662h0z"
    />
  ),
});

export function ConsumerSideBarNav({ hasMenu, isOpen, isVendor }: Props) {
  const { logout, isAuthenticated } = useAuth0();
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  const styles = useMultiStyleConfig('ConsumerSideBarNav', { variant: isMobile && 'mobile' });
  return (
    <Box as="nav" aria-label="Main Navigation" sx={isOpen ? styles.Nav : styles.NavClosed}>
      {hasMenu ? (
        <Flex direction="column" justify="space-between" height="100%">
          <List spacing={1}>
            <ListItem>
              <SideBarNavLink href="/member/profile">
                <CardIcon mr="8px" sx={styles.NavIcon} /> Borrow or return
              </SideBarNavLink>
            </ListItem>
            <ListItem>
              <SideBarNavLink href="/member/payment">
                <PaymentIcon mr="8px" sx={styles.NavIcon} /> Account
              </SideBarNavLink>
            </ListItem>
            <ListItem>
              <SideBarNavLink href="/about-borrower">
                <QuestionMarkIcon mr="8px" sx={styles.NavIcon} /> About
              </SideBarNavLink>
            </ListItem>
          </List>
          <List spacing={1}>
            {isVendor && (
              <ListItem>
                <SideBarNavLink href="/inventory">
                  <InventoryIcon mr="8px" sx={styles.NavIcon} /> Retailers Portal
                </SideBarNavLink>
              </ListItem>
            )}

            <ListItem>
              <Button
                variant="link"
                p={3}
                style={{ fontWeight: 400, color: 'black' }}
                onClick={() => logout({ returnTo: `${window.location.origin}/get-started` })}
              >
                <SignOutIcon mr="8px" sx={styles.NavIcon} /> Sign out
              </Button>
            </ListItem>
          </List>
        </Flex>
      ) : (
        <></>
      )}
    </Box>
  );
}
