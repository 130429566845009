import { Container } from '@chakra-ui/react';
import { ConsumerNav } from 'components/ConsumerNav';
import { Content } from 'components/Content';
import ErrorAccount from 'components/ErrorAccount';
import { Main } from 'components/Main';
import Head from 'next/head';

import { useUserContext } from './UserContext';

type Props = {
  children: JSX.Element | JSX.Element[];
  hasMenu?: boolean;
  pageTitle: string;
};

const ConsumerLayout = ({ children, hasMenu = true, pageTitle }: Props) => {
  
  
  const { user} = useUserContext();

  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"
          rel="stylesheet"
        />
        <title>{pageTitle} | Again Again Consumer</title>
      </Head>
      
      {user?.profile?.onboardingCompleted &&  (<ConsumerNav />) }
      <Main>
        <Container id="vendor-layout" maxW="800px" p={0}>
          <Content>{children}</Content>
        </Container>
      </Main>
    </>
  );
};

export default ConsumerLayout;
