import { Flex } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { useState } from 'react';
import { ConsumerSideBarNav } from './ConsumerSideBarNav';
import { Header } from './Header';
import { CloseIcon } from './Icons/CloseIcon';
import { HamburgerIcon } from './Icons/HamburgerIcon';
import { AgainAgainIconRect } from './Icons/AgainAgainIconRect';
import { useVendorsContext } from './VendorsContext';

import { SkipLink } from './SkipLink';

export function ConsumerNav() {
  const { hasError, isLoading } = useVendorsContext();
  const isVendor = !isLoading && !hasError;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const styles = useMultiStyleConfig('ConsumerNav', {});
  return (
    <>
      <Header>
        <SkipLink />
        <Flex>
          {isSidebarOpen && <CloseIcon onClick={toggleSidebar} aria-label="Open Menu" />}
          {!isSidebarOpen && <HamburgerIcon onClick={toggleSidebar} aria-label="Open Menu" />}
          <Flex position="absolute" top="5px" left="45%" right="45%">
            <AgainAgainIconRect height="27px" margin="auto" />
          </Flex>
        </Flex>
      </Header>
      <ConsumerSideBarNav isOpen={isSidebarOpen} hasMenu={true} isVendor={isVendor} />
    </>
  );
}
